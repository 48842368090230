// IMPORTS
@import "./Variables";
// SPACE
@mixin space(
  $height,
  $width,
  $position: static,
  $top: none,
  $bottom: none,
  $left: none,
  $right: none,
  $translate: false
) {
  height: $height;
  width: $width;
  position: $position;
  @if $position == absolute or $position == fixed {
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
    @if $translate {
      transform: translate(-50%, -50%);
    }
  }
}
// FLEX
@mixin flex($direction, $align, $justify, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}
// FONT
@mixin font($color, $family, $size) {
  color: $color;
  font-family: $family;
  font-size: $size;
}
// BORDER
@mixin border($width, $color, $side: false) {
  @if $side {
    @if $side == top {
      border-top: $width solid $color;
    } @else if $side == bottom {
      border-bottom: $width solid $color;
    } @else if $side == left {
      border-left: $width solid $color;
    } @else if $side == right {
      border-right: $width solid $color;
    }
  } @else {
    border: $width solid $color;
  }
}
// LOGO
@mixin logo {
  @include space(15rem, 35rem);
  @include mobile {
    @include space(12rem, 32rem);
  }
  @include mobileLandscape {
    @include space(12rem, 32rem);
  }
}
// TITLE
@mixin title($color) {
  @include font($color, $secondFont, 6.4rem);
  line-height: 6.4rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  @include tablet {
    font-size: 5rem;
    line-height: 5rem;
  }
  @include tabletLandscape {
    font-size: 5rem;
    line-height: 5rem;
  }
  @include mobile {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
  @include mobileLandscape {
    display: none;
  }
}
// TEXT
@mixin text($color) {
  @include font($color, $firstFont, 3rem);
  font-weight: 700;
  line-height: 3.8rem;
  text-align: center;
  text-transform: uppercase;
}
// BUTTON
@mixin button($background, $color) {
  @include space(6.5rem, 30rem, relative);
  @include flex(row, center, center);
  @include font($color, $firstFont, 2rem);
  background: $background;
  border-radius: 3rem;
  box-shadow: $boxShadow;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    background: darken($background, 8);
  }
  &:active {
    box-shadow: $boxShadowAcitve;
    transform: translateY(3px);
  }
  @include tablet {
    background: $background;
  }
}
// MEDIA QUERIES
@mixin mobile {
  @media screen and (max-width: $mobileBreakpoint) {
    @content;
  }
}
@mixin mobilePortrait {
  @media screen and (max-width: $mobileBreakpoint) and (orientation: "portrait") {
    @content;
  }
}
@mixin mobileLandscape {
  @media screen and (max-height: $mobileBreakpoint) and (orientation: "landscape") {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: $tabletBreakpoint) {
    @content;
  }
}
@mixin tabletPortrait {
  @media screen and (max-width: $tabletBreakpoint) and (orientation: "portrait") {
    @content;
  }
}
@mixin tabletLandscape {
  @media screen and (max-height: $tabletBreakpoint) and(orientation: "landscape") {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: $tabletBreakpoint) {
    @content;
  }
}

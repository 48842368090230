// IMPORTS
@import "../Mixins";
@import "../Variables";
// CONTAINER
.loginContainer,
.loginContainerMove {
  @include space(100%, 100%, absolute);
  @include flex(row, flex-end, center);
  padding-right: 10rem;
  @include tablet {
    padding-right: 0rem;
  }
  @include mobile {
    @include flex(column-reverse, center, center);
  }
}
.loginContainerMove {
  left: 100vw;
  animation: slideOut 0.4s ease;
}
@keyframes slideOut {
  0% {
    left: 0rem;
  }
  100% {
    left: 100vw;
  }
}
// BORED BISHOP
.loginBoredBishop {
  @include space(80rem, auto);
  margin-right: 3rem;
  @include tablet {
    height: 50rem;
    margin-right: 0rem;
  }
  @include mobile {
    height: 50%;
    margin-right: 0rem;
  }
  @include mobileLandscape {
    height: 95%;
    margin-right: 0rem;
  }
}
// CONTENT
.loginContent {
  @include space(80rem, 45rem);
  @include flex(column, flex-start, flex-start);
  padding-top: 10rem;
  margin-left: 3rem;
  @include tablet {
    height: 60rem;
  }
  @include mobile {
    height: 50%;
    align-items: center;
    justify-content: center;
    padding-top: 0rem;
    margin-left: 0rem;
  }
  @include mobileLandscape {
    height: 100%;
    padding-top: 0rem;
    justify-content: center;
  }
}
// SPEECH BUBBLE
.loginSpeechBubble {
  @include space(30rem, 45rem);
  @include flex(row, center, center);
  @include text($black);
  background: url("../../assets/images/speech-bubble.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 5rem;
  @include tablet {
    @include space(20rem, 35rem);
    font-size: 2.5rem;
  }
  @include mobile {
    margin-bottom: 2rem;
  }
  @include mobileLandscape {
    margin-bottom: 2rem;
  }
}
// INPUT
.loginInputContainer {
  @include space(6.5rem, 30rem, relative);
}
.loginInput {
  @include space(100%, 100%);
  @include font($black, $firstFont, 2rem);
  @include border(0.4rem, $black);
  background: $white;
  border-radius: 3rem;
  text-align: center;
  text-transform: uppercase;
  &::placeholder {
    @include font($gray, $firstFont, 2rem);
    text-align: center;
    text-transform: uppercase;
  }
}
.loginInputToggleButton {
  @include space(100%, 6.5rem, absolute, 0rem, none, none, 0rem);
  @include flex(row, center, center);
  @include font($black, $firstFont, 3rem);
  cursor: pointer;
}
// BUTTON
.loginButton,
.loginShakingButton {
  @include button($yellow, $black);
  margin-top: 1rem;
}
.loginShakingButton {
  animation: shake 0.4s ease-in-out;
}
// SHAKE ANIMATION
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(5deg);
  }
  20% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-5deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-5deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

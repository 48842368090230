// IMPORTS
@import "./Mixins";
@import "./Variables";
@import "./modules/Login";
@import "./modules/Mint";
// REGULARIZATION
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  resize: none;
  background: none;
  user-select: none;
}
// SCROLL BAR
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
*::-webkit-scrollbar-thumb {
  background: transparent;
}
// DEFAULT STYLES
:root {
  font-size: 62.5%;
}
body {
  background: $babyBlue;
  overflow: hidden;
}
// APP
.appContainer {
  @include space(100vh, 100vw, relative);
  @include flex(row, center, center);
  background: url("../assets/images/background.jpeg");
  background-size: cover;
  @include tablet {
    overflow: hidden;
  }
  @include tabletLandscape {
    overflow: hidden;
  }
}

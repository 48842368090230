// FONT FAMILIES
@font-face {
  font-family: ProvidenceSans;
  src: url("../assets/fonts/Providence-Sans.ttf") format("truetype");
}
@font-face {
  font-family: PeaceSans;
  src: url("../assets/fonts/Peace-Sans.otf") format("opentype");
}
$firstFont: "ProvidenceSans", sans-serif;
$secondFont: "PeaceSans", sans-serif;
// COLORS
$black: #000000;
$white: #ffffff;
$gray: #999999;
$yellow: #ffde16;
$blue: #0f3d8e;
$babyBlue: #35d5fa;
// SPACING
$spacing: 4rem;
// BOX SHADOWS
$boxShadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.3);
$boxShadowAcitve: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
// BREAK POINTS
$mobileBreakpoint: 700px;
$tabletBreakpoint: 1050px;

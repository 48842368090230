// IMPORTS
@import "../Mixins";
@import "../Variables";
// CONTAINER
.mintContainer {
  @include space(100%, 100%, absolute);
  @include flex(column, center, center);
  animation: slideIn 0.4s ease;
}
@keyframes slideIn {
  0% {
    left: -100vw;
  }
  100% {
    left: 0rem;
  }
}
// LOGO
.mintLogo {
  @include logo();
  margin-bottom: $spacing;
}
// TITLE
.mintTitle {
  @include title($blue);
  margin-bottom: $spacing;
}
// WALLET BUTTON
.mintWalletButton {
  @include button($white, $blue);
  margin-bottom: $spacing;
}
// CHAIN BUTTON
.mintChainButton {
  @include button($white, $blue);
  margin-bottom: $spacing;
}
// MINT BUTTON
.mintButton {
  @include button($yellow, $black);
  margin-bottom: $spacing;
}
// MESSAGE
.mintMessage {
  @include text($blue);
  margin-bottom: $spacing;
  font-size: 4rem;
  @include tablet {
    font-size: 3rem;
  }
  @include tabletLandscape {
    font-size: 3rem;
  }
  @include mobile {
    font-size: 2.3rem;
    width: 90%;
  }
  @include mobileLandscape {
    font-size: 2.3rem;
  }
}
// LINK
.mintLink {
  @include button($yellow, $blue);
  margin-top: $spacing;
  padding: 0rem 2rem;
  width: auto;
  max-width: 95%;
  @include tabletPortrait {
    font-size: 1.7rem;
  }
  @include mobile {
    height: 3rem;
    font-size: 0.9rem;
    padding: 0rem 1rem;
  }
  @include mobileLandscape {
    height: 4rem;
    font-size: 1.5rem;
    margin: 0rem;
  }
}
.mintLinkInstructions {
  @include space(auto, auto, absolute, -4rem);
  @include text($blue);
  font-size: 2rem;
  @include mobile {
    top: -3rem;
    font-size: 1.5rem;
  }
  @include mobileLandscape {
    top: -3rem;
    font-size: 1.5rem;
  }
}
// THROBBER
.mintThrobber {
  @include space(3rem, 3rem, absolute, none, none, none, 2rem);
  @include border(0.5rem, $black);
  @include border(0.5rem, transparent, bottom);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
